import Swiper from 'swiper';

export function index(){
    if(document.getElementById('firstview')){
        const fv = document.getElementById('firstview');
        setTimeout(function(){
            fv.classList.add('active');
        },600);
    }
    
    const indexswipe =  document.getElementById('fvswipe');
    if(indexswipe){
        let plam = {
            autoplay: {
            	delay: 5000,
            	disableOnInteraction: false,
            },
            slidesPerView: 1,
            centeredSlides: false,
			effect: 'fade',
			speed: 2000,
            spaceBetween: 0,
            loop: true,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#fvswipe' , plam);
    }
    
    const voiceswipe =  document.getElementById('voiceswipe');
    if(voiceswipe){
        let voice_plam = {
            autoplay: {
            	delay: 5000,
            	disableOnInteraction: false,
            },
            slidesPerView: 'auto',
            centeredSlides: true,
			speed: 600,
            spaceBetween: 0,
            loop: true,
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#voiceswipe' , voice_plam);
    }
}