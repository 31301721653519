export function vues(){
    const sidebar = new Vue({
        el: '#vside',
        data: {
            area: false,
        } 
    });
    const header = new Vue({
        el: '#site-header',
        data: {
            flag: false,
            activate: false
        },
        mounted: function(){
            this.activate = true;
        }
    });
}