export function scrolEvents(controller , scene){
    scene.push(
        new ScrollMagic.Scene({
            triggerElement: 'body',
            offset: window.innerHeight * 1.5
        })
        //.addIndicators()
        .setClassToggle('#site-header','active').addTo(controller)
    )
	
    scene.push(
        new ScrollMagic.Scene({
            triggerElement: 'body',
            offset: window.innerHeight * 1.5
        })
        //.addIndicators()
        .setClassToggle('#pagetop','active').addTo(controller)
    )
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}
    
	const scroll_custum_name = 'scroll-custum';
	const scroll_custum_elem = document.getElementsByClassName(scroll_custum_name);
	if(scroll_custum_elem.length > 0){
		for(let i = 0; i < scroll_custum_elem.length; i++){
			let custum_node = scroll_custum_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: custum_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(custum_node,'_active').addTo(controller)
			)
		}
	}
	
	const scroll_img_name = 'scroll-img';
	const scroll_img_elem = document.getElementsByClassName(scroll_img_name);
	if(scroll_img_elem.length > 0){
		for(let i = 0; i < scroll_img_elem.length; i++){
			let scroll_img_node = scroll_img_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_img_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(scroll_img_node,'__active').addTo(controller)
			)
		}
	}

    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
      offset: 0
    });
    const slice_text = document.getElementsByClassName('txt_slice');
    for(let i = 0; i < slice_text.length; i++){
        slicer(slice_text[i]);
    }

    function slicer(elem){
        const txtchange =  elem.innerHTML.replace(/<br>/g, '_');
        const txt =  txtchange;
        elem.innerHTML = '';
        txt.split('').forEach(function(c) {
            if(c == '_'){
                elem.innerHTML += '<br>';
            }
            else if(c == ' '){
                elem.innerHTML += '<span>&nbsp;</span>';
            }
            else{
                elem.innerHTML += '<span>'+c+'</span>';
            }
        });
    }

}